import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import QrIcon from './QR-code'
import CopyLinkIcon from './copy-link'
import BuddyLinkIcon from './buddy-link'
import Zoom from './zoom/index'

import Dropdown from '../utilities/dropdown-menu'

import { connect } from 'react-redux';

import { DesktopList, MobileList } from '../devices/device-list'
import { FeedList } from './pro-skin-feed-width'
import Tooltip from '../utilities/Tooltip'
import { isScroller, isScrollo, isResponsive, getQueryStringParams } from '../utilities/customFunctions'
import { useLocation } from "react-router"
import { config } from '../../helpers'
import { templateLists } from '../preview/templates/helpers'

const Wrapper = styled.div`
    width: 100%;
    height: 55px;
    background: #2E3547;
    opacity: 0.95;
    padding: 0 10px;
    display: flex;
    justify-content: between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1000;
`;

const WrapMiddle = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Title = styled.div`
    color: white;  
    flex-grow: 1;    
`;

const RefreshPreview = styled.img`
    width: 24px;
    height: 24px;
    margin-left: 10px;
    cursor: pointer;
`

const PublisherPreviewList = [ ...templateLists]

const Headers = (props) => {

  const [deviceList, setDeviceList] = useState()
  const [previewList, setPreviewList] = useState()
  const [template, setTemplate] = useState()

  const { details, device } = props;

  //let isSkinCreative = (details && details.ad_type && isSkin(details.ad_type));
  let isScrolloCreative = (details && details.ad_type && isScrollo(details.ad_type));
  let isResponsiveCreative = (details && details.dimension && isResponsive(details.dimension));
  //eslint-disable-next-line
  let isDesktop = (details && details.device == "1")
  let { search } = useLocation()

  const [feedWidthList, setFeedWidthList] = useState(FeedList);

  useEffect(() => {
    if (details && !device) {
      let searchParams = getQueryStringParams(search);
      if(searchParams && searchParams.siteId){
        if(PublisherPreviewList[PublisherPreviewList.findIndex(x => x.id === searchParams.siteId)]){
          let temp = JSON.parse(JSON.stringify(PublisherPreviewList));
          //eslint-disable-next-line
          temp.forEach(item => item.selected = (item.id == searchParams.siteId) ? true : false);
          setPreviewList([...temp]);
          setTemplate(PublisherPreviewList[PublisherPreviewList.findIndex(x => x.id === searchParams.siteId)])
        }
      } else{
        setPreviewList(PublisherPreviewList)
      }
      //eslint-disable-next-line
      if (details.device == "1") {
        setDeviceList(DesktopList)
        if (!device && !isDesktop && !isScrolloCreative) {
          deviceList && deviceList.length > 0 && resetThenSet(deviceList[0].id, "", "", 0);
          setDeviceList(DesktopList.slice(0, 3));
        }

        //if(isSkinCreative){
        document.body.classList.add("show-header");
        if (searchParams && searchParams.feedwidth) {

          let temp = JSON.parse(JSON.stringify(feedWidthList));
          let isInvalidWidth = true;
          temp.forEach(item => {
            item.selected = false;
            // eslint-disable-next-line
            if (item.id == searchParams.feedwidth) {
              item.selected = true;
              isInvalidWidth = false;
              document.getElementById("bm-creative-preview").style.width = searchParams.feedwidth + 'px';
              document.getElementById("bm-creative-preview").style.margin = '0 auto';
              document.getElementById("bm-desktop-container").style.maxWidth = (searchParams.feedwidth) + 'px';
            }
          });
          if (isInvalidWidth) {
            setFeedWidthList(feedWidthList);
          }
          else {
            setFeedWidthList([...temp]);
          }

        }
        else {
          setFeedWidthList(FeedList);
        }
        //}

      }
      else {
        if (isScroller(details.format_type)) {
          setDeviceList(MobileList.slice(0, 3));
        }
        else {
          setDeviceList(MobileList.slice(0, 6));
        }
      }

    }
  }, [details, device, search])

  const resetThenSet = (id, title, key, index) => {
    let temp = JSON.parse(JSON.stringify(deviceList));
    temp.forEach(item => item.selected = false);
    temp[index].selected = true;

    setDeviceList([...temp]);
    props.onDeviceChange(id);
  }
  const resetThenSetPreview = (id, title, key, index) => {
    if (getUrl().indexOf("creative") > -1) {
      window.location = `/creative/${details.id}?siteId=${id}`;
    }
    else {
      window.location = `/${details._id}?siteId=${id}`;
    }
  }
  const resetThenSetFeedWidth = (id, title, key, index) => {
    let temp = JSON.parse(JSON.stringify(feedWidthList));
    temp.forEach(item => item.selected = false);
    temp[index].selected = true;

    setFeedWidthList([...temp]);

    if (getUrl().indexOf("creative") > -1) {
      window.location = `/${id}/creative/${details.id}?feedwidth=${id}`;
    }
    else {
      window.location = `/${id}/${details._id}?feedwidth=${id}`;
    }
  }

  function getUrl() {
    return window.location.href;
  }

  function getDealUrl(details) {
    const { ad_id, _id } = details;

    return `${config.buddyServer}/my-deals/creatives?ad_id=${ad_id}&cre_id=${_id}`;
  }

  function renderInfo(details) {
    const { ad_type, dimension } = details;
    return (
      <div>
        <p className="mb-1 font-weight-bold">Size: {dimension}</p>
        <p className="font-weight-bold">Format: {ad_type}</p>
      </div>
    )

  }
  return (
    <Wrapper>
       {
        (config.authId() && props.details && props.details._id && props.details.ad_id) ? <BuddyLinkIcon url={getDealUrl(props.details)} /> : null
      }
      <Title>
        {props.details ?
          props.details.country ?

            <Tooltip light={true} className="m-0 cursor-pointer" label={`Creative ${props.details ? " - " + props.details.ad_name : ""}`} iconname="fa-info-circle">
              <div className="p-2 text-left maxWidth-300">
                {renderInfo(props.details)}
                {/* <Benchmark /> */}
              </div>
            </Tooltip>
            :
            <Tooltip light={true} className="m-0 cursor-pointer" label={`Creative ${props.details ? " - " + props.details.ad_name : ""}`} iconname="fa-info-circle">
              <div className="p-2 text-left maxWidth-300">{renderInfo(props.details)}</div>
            </Tooltip>
          : ""
        }
      </Title>
      <WrapMiddle>
      {
        deviceList && deviceList.length && !isDesktop && !isScrolloCreative && !isResponsiveCreative &&
        <Dropdown
          title="Select device"
          list={deviceList}
          resetThenSet={resetThenSet}
        />
      }
      {!isDesktop && !isScrolloCreative && !isResponsiveCreative &&
        <>
          <Zoom />
          <RefreshPreview title="Reload ad" className="refresh-preview" src="/images/icons/refresh-icon.png" alt="" onClick={props.onRefreshClick} />
        </>
      }

      {
        isDesktop ?
          <Dropdown
            key={Date.now()}
            title="Width"
            list={feedWidthList}
            disabled={template ? true : false}
            resetThenSet={resetThenSetFeedWidth}
            headerClass={template? "disable" : ""}
          />
          : null
      }
      <QrIcon url={getUrl()} />
      <CopyLinkIcon url={getUrl()} />
      </WrapMiddle>
      
      {
        previewList && previewList.length &&
        <React.Fragment>
        <Dropdown
          title="Simulate publisher preview"
          list={previewList}
          resetThenSet={resetThenSetPreview}
          headerClass={getQueryStringParams(search).siteId ? "dd-header-border-main" : "dd-header-main"}
        />
      
        <span className={getQueryStringParams(search).siteId ? "dd-color-main cursor-pointer" : "dd-color-semi-main cursor-pointer"} onClick={() => {getQueryStringParams(search).siteId && resetThenSetPreview("")}}><i className="fas fa-power-off fa-lg"></i></span>
        </React.Fragment>
      }
    </Wrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    details: state.previewAd.details,
    loading: state.previewAd.loading,
    device: state.device.id
  }
};


export default (connect(mapStateToProps, null)(Headers));
