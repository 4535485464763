import React, { Component } from 'react';
import postscribe from 'postscribe'
import { getPreviewContainerWidth, isMobile, getQueryStringParams, isScroller, isInterstitial, isSkin, isResponsive, getGeneratedPageURL, isSwing} from '../../utilities/customFunctions'
import { config } from '../../../helpers';
import axios from 'axios'
import { templateLists } from '../templates/helpers';
import { withRouter } from '../../HOC/withRouter';
class PreviewNexd extends Component {

  getUA(device, ua) {
    let userAgent = '';
    if (ua) {
      // eslint-disable-next-line
      if (device == "1") {
        userAgent = ua.desktop;
      } else {
        userAgent = ua.mobile;
      }
    }
    else {
      // eslint-disable-next-line
      if (device == "1") {
        userAgent = "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3770.100 Safari/537.36";
      } else {
        userAgent = "Mozilla/5.0 (Linux; Android 5.0; SM-G900P Build/LRX21T) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Mobile Safari/537.36";
      }
    }

    return {
      get: function () { return userAgent; }
    };
  }

  setUserAgent(window) {
    const { details, nexd } = this.props;

    const userAgentProp = this.getUA(details.device, nexd && nexd.ua);
    try {
      Object.defineProperty(window.navigator, 'userAgent', userAgentProp);
    } catch (e) {
      window.navigator = Object.create(navigator, {
        userAgent: userAgentProp
      });
    }
  }

  async componentDidMount() {
    const { details, isDesktop, isResponsivePreview, isShowcasePreview = false } = this.props;
    let searchParams = getQueryStringParams(this.props.location.search);
    let template = false
    let templateId = false

    if (searchParams && searchParams.siteId) {
      templateId = searchParams.siteId
      if (templateId && templateLists[templateLists.findIndex(x => x.id === templateId)]) {
        template = templateLists[templateLists.findIndex(x => x.id === templateId)]
      }
    }
    
  
    if (details.scripts && details.scripts.secureScript) {
      let isScrollerCreative = isScroller(details.format_type);
      let isInterstitialCreative = isInterstitial(details.format_type);
      let isSwingCreative = isSwing(details.ad_type)
      let isSkinCreative = isSkin(details.ad_type);
      let widthToAddForDesktop = 0;
      if(isSkinCreative){
        widthToAddForDesktop = 0;
      }

      let isResponsiveCreative = details && details.dimension && isResponsive(details.dimension);
      let isMobileSkinCreative = isSkinCreative && !isDesktop;

      if(isResponsiveCreative || isMobileSkinCreative || isSwingCreative){
        isInterstitialCreative = true;
      }
      // eslint-disable-next-line
      var browserWidth = isDesktop ? (Number(details.width) + widthToAddForDesktop)  : getPreviewContainerWidth(details.width) ;
      var dynHeight = Math.floor((browserWidth * details.height) / details.width); 
      
      if(isScrollerCreative && !isDesktop){
        //document.getElementById("bm-preview-iframe-container").style.width = 320 + "px";
        //document.getElementById("bm-preview-iframe-container").style.height = dynHeight + "px";
        browserWidth = document.getElementsByClassName("bm-creative-preview")[0].clientWidth;
        dynHeight = document.getElementsByClassName("bm-creative-preview")[0].clientHeight;
      }
      else if(isInterstitialCreative && !isDesktop){
        if(document.getElementById("bb-device-frame-body")){
          document.getElementById("bb-device-frame-body").style.overflowY = "hidden";
          browserWidth = document.getElementById("bb-device-frame-body").clientWidth;
          dynHeight = document.getElementById("bb-device-frame-body").clientHeight; 
        }
        else if(document.getElementById("bb-device-frame-content-body")){
          document.getElementById("bb-device-frame-content-body").style.overflowY = "hidden";
          document.getElementById("bm-creative-container").style.borderRadius ="30px";
          document.getElementById("bm-creative-container").style.overflow ="hidden";
          browserWidth = document.getElementById("bb-device-frame-content-body").clientWidth;
          dynHeight = document.getElementById("bb-device-frame-content-body").clientHeight; 
          
        }
        else{
            browserWidth = window.innerWidth;
            dynHeight = window.innerHeight;         
        }
      }
      var replaceIns = `<ins \n\t data-tracking="no" data-trackingjs="no" data-debug="true" data-cachebuster="${Date.now()}" \n\t`;

      if(!isScrollerCreative){
        replaceIns = replaceIns + ` data-stay-in-container="true"  \n\t`;
      }
      else{
        //if(!isMobile()){
          replaceIns = replaceIns + ` data-stay-in-container="true"  \n\t`
        //}
      }
      //var replaceIns = `<ins \n\t data-stay-in-container="true" data-tracking="no" data-trackingjs="no" data-preview-site="true" data-debug="true" data-cachebuster="${Date.now()}" \n\t`;
      if(isShowcasePreview){
        replaceIns = replaceIns + ` data-clickable="no" \n\t`;
      }

      // eslint-disable-next-line
      let tagWithoutTrackings = details.scripts.secureScript.replace (/(^")|("$)/g, '').replace(/\<ins/igm, replaceIns);
      
      if(details.ad_server && details.ad_server === "airtory" ){
        if(details.html_tag){
          tagWithoutTrackings = details.html_tag.replace("preview/" + details.creative_id , "preview/" + details.creative_id +"?d=" + Date.now()); 
        }
        if(!isMobileSkinCreative && !isSwingCreative){
          browserWidth = details.width;
          dynHeight = details.height;
        }       
       /*  if(details.scripts.adcId){
          tagWithoutTrackings = details.scripts.secureScript.replace(details.scripts.adcId + ".js", details.scripts.adcId + ".js?d=" + Date.now());
        } */
      }
      else if (details.ad_server && details.ad_server === "primo") {
        if (details.html_tag) {
          tagWithoutTrackings = details.html_tag;
        }
      }

      if (isShowcasePreview && (/id="ad(.*?)(\/placement)/igm).test(tagWithoutTrackings)) {      
        let adcId = ""
        // eslint-disable-next-line
        let expDCM = new RegExp(`(id="ad)(.*?)(\/placement)`, "igm");
        tagWithoutTrackings.replace(expDCM, function (match, prefix, handler, suffix) {
                                adcId = handler;
                                return handler;
                            });   
        let t = async () => {
          let res = await axios.get(`${config.apiServer}/get-a-preview-tag?id=${adcId}`);

          let { data } = res;     
      
          if(data && data.tag){
            tagWithoutTrackings  = data.tag.replace(/var adTag/igm, "var data={url: 'https://bigads.co'}; var adTag") 
            if(!isMobileSkinCreative){
              browserWidth = details.width;
              dynHeight = details.height;
            }
          }          
        }    
        await t();    
      }

      if(isSkinCreative && isDesktop){
        postscribe("#bm-preview-iframe-container", tagWithoutTrackings,
        {

        });
      } 
      else{
        // eslint-disable-next-line
        let htmlCode = getGeneratedPageURL(tagWithoutTrackings, details.width == "320", details.ad_server, isInterstitial(details.dimension), isMobileSkinCreative, isSwingCreative || details.ad_type === "Airtime", template);

        if(isResponsivePreview && !isMobile()){
      
          let iframeContent = `
          <div class="d-flex flex-column justify-content-center my-5 flex-wrap">
            <div class="d-flex flex-row flex-wrap justify-content-start">
              <div class="d-flex flex-column mb-3 pr-5">
                <span class="mb-2">300x600</span>
                <iframe width="300px" 
                  height="600px" id="bm-creative1" class="pointer-auto border-solid-white" scrolling="no" frameborder="0" src='${htmlCode}' >  
                </iframe> 
              </div>
              <div class="d-flex flex-column">
              <span class="mb-2">320x480</span>
                <iframe width="320px" 
                  height="480px" id="bm-creative2" class="pointer-auto border-solid-white" scrolling="no" frameborder="0" src='${htmlCode}' >  
                </iframe> 
              </div>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-start">
              <div class="d-flex flex-column mb-3 pr-5 ">
                <span class="mb-2">300x150</span>
                <iframe width="300px" 
                  height="150px" id="bm-creative3" class="pointer-auto border-solid-white" scrolling="no" frameborder="0" src='${htmlCode}' >  
                </iframe> 
              </div>
              <div class="d-flex flex-column">
              <span class="mb-2">300x250</span>
                <iframe width="300px" 
                  height="250px" id="bm-creative4" class="pointer-auto border-solid-white" scrolling="no" frameborder="0" src='${htmlCode}' >  
                </iframe> 
              </div>
            </div>             
          </div>       
          `
          if(isDesktop){
            iframeContent = `
            <div class="d-flex flex-column justify-content-center my-5 flex-wrap">
              <div class="d-flex flex-row flex-wrap justify-content-start">
                <div class="d-flex flex-column mb-3 pr-5">
                  <span class="mb-2">300x600</span>
                  <iframe width="300px" 
                    height="600px" id="bm-creative1" class="pointer-auto border-solid-white" scrolling="no" frameborder="0" src='${htmlCode}' >  
                  </iframe> 
                </div>
                <div class="d-flex flex-column">
                <span class="mb-2">300x250</span>
                  <iframe width="300px" 
                    height="250px" id="bm-creative2" class="pointer-auto border-solid-white" scrolling="no" frameborder="0" src='${htmlCode}' >  
                  </iframe> 
                </div>
              </div>
              <div class="d-flex flex-row flex-wrap justify-content-start">
                <div class="d-flex flex-column mb-3 pr-5 ">
                  <span class="mb-2">970x250</span>
                  <iframe width="970px" 
                    height="250px" id="bm-creative3" class="pointer-auto border-solid-white" scrolling="no" frameborder="0" src='${htmlCode}' >  
                  </iframe> 
                </div>
              </div>             
          </div> 
            `
          }

          document.getElementById("bm-preview-iframe-container").innerHTML = iframeContent;
          if (!isMobile() && !isDesktop) {
            this.setUserAgent(document.querySelector('#bm-creative1').contentWindow);
            this.setUserAgent(document.querySelector('#bm-creative2').contentWindow);
            this.setUserAgent(document.querySelector('#bm-creative3').contentWindow);
            this.setUserAgent(document.querySelector('#bm-creative4').contentWindow);
          } 

        }
        else{
          let waitTime = 1;
          if(isInterstitialCreative || isSwingCreative || isMobileSkinCreative){
              waitTime = 1000; 
          } 
          setTimeout( () => {

          if(isInterstitialCreative && isResponsiveCreative){
            browserWidth = window.innerWidth;
            dynHeight = window.innerHeight;     
          }

          if((isMobileSkinCreative || isSwingCreative) && !browserWidth){
            browserWidth = document.getElementsByClassName("bm-creative-preview")[0].clientWidth;
            dynHeight = document.getElementsByClassName("bm-creative-preview")[0].clientHeight;
          }

          if ((details.ad_server && details.ad_server === "primo" ) || details?.ad_type === "Dimensional" || details?.ad_type === "AnimatedFX") {
            browserWidth = details.width;
            dynHeight = details.height
          }

          let iframeContent = `
          <iframe width=${browserWidth}
            height=${dynHeight} id="bm-creative" name=${((isMobileSkinCreative && !isMobile()) || isSwingCreative) ? 'native' : 'buddy'} class="pointer-auto mx-auto" scrolling=${isMobileSkinCreative || isSwingCreative ? 'yes' : 'no'} frameborder="0" src='${htmlCode}' >  
          </iframe>             
          `
          if(details.ad_type === "Airtime"){
            iframeContent = `
            <iframe width=${browserWidth} onload="loadedIframe()"
              height=${dynHeight} id="bm-creative" name=${((isMobileSkinCreative && !isMobile()) || isSwingCreative) ? 'native' : 'buddy'} class="pointer-auto mx-auto" scrolling=${isMobileSkinCreative || isSwingCreative ? 'yes' : 'no'} frameborder="0" src='${htmlCode}' >  
            </iframe>             
            `
          }

          document.getElementById("bm-preview-iframe-container").innerHTML = iframeContent;
          if (!isMobile()) {
            this.setUserAgent(document.querySelector('#bm-creative').contentWindow);
          }
            },waitTime)
        }
     
        
      }
                     

      //let searchParams = getQueryStringParams(this.props.location.search);
      let isFocus = false;
      if (searchParams && searchParams.focusAd) {
        isFocus = searchParams.focusAd === "true" ? true : false;
      }

      if (isFocus) {
        this.scrollViewToAd();
      }

    }
  }


  scrollViewToAd = () => {
    const id = "bm-creative-container";
    const $anchor = document.getElementById(id);
    const offsetTop = $anchor.getBoundingClientRect().top + document.getElementsByClassName("bm-creative-preview")[0].scrollTop;


    const { details } = this.props;
    let height = details && details.height;
    let creativeHeight = $anchor.offsetHeight || height;
    let scrollToNumber = creativeHeight / 2;
    
    if(creativeHeight){
      scrollToNumber = Math.abs( ((570 - ( creativeHeight )) / 2) - 10 );
    }
      setTimeout(function () {
        document.getElementsByClassName("bm-creative-preview")[0].scroll({
          top: offsetTop - scrollToNumber,
          behavior: 'smooth'
        })
      }, 300);

  }

  render() {
    //const { details, nexd } = this.props;
    return (
      //nexd && <div id="bm-creative"></div>      
      <div id="bm-preview-iframe-container" className="mx-auto"></div>
      /*  details && <iframe src="" srcDoc={nexd.tag} width={details.width} 
       height={details.height} id="bm-creative" scrolling="no" frameBorder="0"></iframe>   */
    );
  }
}

export default withRouter(PreviewNexd); 
